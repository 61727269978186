import judgementApi from '../../common/judgementApi';
import Race from './interfaces/Race';

const racesApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getRaces: build.query<Race[], void>({
            query: () => 'races',
        }),

        getRaceByName: build.query<Race, string>({
            query: (raceName) => `races/${raceName}`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetRacesQuery, useGetRaceByNameQuery } = racesApi;
