import { Skeleton, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Link from '@mui/material/Link';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useGetGodByNameWithFaqsQuery } from './godsApi';
import artefactItem from '../../common/components/ArtefactItem';
import faqItem from '../../common/components/FaqItem';

function God() {
    const { godUrlName } = useParams() as { godUrlName: string };
    const { data: god, isLoading } = useGetGodByNameWithFaqsQuery(godUrlName);

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!god) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>God not Found</Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <div>
                        <img
                            src={`/images/${god.logo}`}
                            alt={god.logo}
                            width="220"
                            height="220"
                        />
                        <Typography variant="h1">{god.name}</Typography>
                        <div>
                            <i>{god.traits}</i>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div>
                        <p>{god.description}</p>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <h2>Champions</h2>
                    <div>
                        <img
                            src={`/images/avatar-${god.avatar.url_name}.jpg`}
                            alt={god.avatar.name}
                            width="220"
                            height="220"
                        />
                    </div>
                    <div>
                        <h3>
                            <Link
                                component={RouterLink}
                                to={`/heroes/${god.avatar.url_name}`}
                                underline="hover"
                                color="secondary"
                            >
                                {god.avatar.name}
                            </Link>{' '}
                            (Avatar)
                        </h3>
                    </div>
                    {god.champions.map((champion) => {
                        return (
                            <div key={champion.url_name}>
                                <Link
                                    component={RouterLink}
                                    to={`/heroes/${champion.url_name}`}
                                    underline="hover"
                                    color="secondary"
                                >
                                    {champion.name}
                                </Link>
                            </div>
                        );
                    })}
                </Grid>
                <Grid xs={12}>
                    <h2>Divine Gifts</h2>
                    <h3>Warband Bonus</h3>
                    <div>{god.divineGifts.warband_bonus}</div>
                    <h3>Effigy Power - {god.divineGifts.effigy_power.name}</h3>
                    <div>{god.divineGifts.effigy_power.description}</div>
                    <h4>Avatar Bonus</h4>
                    <div>{god.divineGifts.effigy_power.avatar_bonus}</div>
                    <h3>Sacred Artefact</h3>
                    {artefactItem(god.sacredArtefact)}
                </Grid>
            </Grid>
            <br />
            {god.faqs.length === 0 ? (
                ''
            ) : (
                <Grid container spacing={2}>
                    <Typography variant="h1">{god.name} FAQs</Typography>
                    {god.faqs.map((faq) => {
                        return faqItem(faq);
                    })}
                </Grid>
            )}
        </Box>
    );
}

export default God;
