import { Skeleton, FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import HeroCard from './HeroCard';
import { useGetHeroesQuery, useGetHeroClassesQuery } from './heroesApi';
import { useGetGodsQuery } from '../gods/godsApi';
import { useGetRacesQuery } from '../races/racesApi';

function Heroes() {
    const { data: heroes, isLoading: heroesIsLoading } = useGetHeroesQuery();
    const { data: gods, isLoading: godsIsLoading } = useGetGodsQuery();
    const { data: races, isLoading: racesIsLoading } = useGetRacesQuery();
    const { data: heroClasses, isLoading: heroClassesIsLoading } =
        useGetHeroClassesQuery();
    const [selectedHero, setSelectedHero] = useState('All Heroes');
    const [selectedRace, setSelectedRace] = useState('All Races');
    const [selectedGod, setSelectedGod] = useState('All Gods');
    const [selectedClass, setSelectedClass] = useState('All Classes');

    const handleHeroChange = (event: SelectChangeEvent) => {
        setSelectedHero(event.target.value as string);
    };
    const handleRaceChange = (event: SelectChangeEvent) => {
        setSelectedRace(event.target.value as string);
    };
    const handleGodChange = (event: SelectChangeEvent) => {
        setSelectedGod(event.target.value as string);
    };
    const handleClassChange = (event: SelectChangeEvent) => {
        setSelectedClass(event.target.value as string);
    };

    if (
        heroesIsLoading ||
        godsIsLoading ||
        heroClassesIsLoading ||
        racesIsLoading
    ) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Grid container spacing={2}>
            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                <InputLabel id="heroLabel">Hero</InputLabel>
                <Select
                    labelId="selectHerolabel"
                    id="selectHero"
                    value={selectedHero}
                    label="Hero"
                    onChange={handleHeroChange}
                >
                    <MenuItem value="All Heroes">All Heroes</MenuItem>
                    {heroes?.map((hero) => (
                        <MenuItem value={hero.name}>{hero.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                <InputLabel id="raceLabel">Race</InputLabel>
                <Select
                    labelId="selectRacelabel"
                    id="selectRace"
                    value={selectedRace}
                    label="Race"
                    onChange={handleRaceChange}
                >
                    <MenuItem value="All Races">All Races</MenuItem>
                    {races?.map((race) => (
                        <MenuItem value={race.name}>{race.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                <InputLabel id="godLabel">God</InputLabel>
                <Select
                    labelId="selectGodlabel"
                    id="selectGod"
                    value={selectedGod}
                    label="God"
                    onChange={handleGodChange}
                >
                    <MenuItem value="All Gods">All Gods</MenuItem>
                    {gods?.map((god) => (
                        <MenuItem value={god.name}>{god.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                <InputLabel id="classLabel">Class</InputLabel>
                <Select
                    labelId="selectClasslabel"
                    id="selectClass"
                    value={selectedClass}
                    label="Class"
                    onChange={handleClassChange}
                >
                    <MenuItem value="All Classes">All Classes</MenuItem>
                    {heroClasses?.map((heroClass) => (
                        <MenuItem value={heroClass.name}>
                            {heroClass.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {heroes
                ?.filter(
                    (hero) =>
                        hero.race ===
                        (selectedRace === 'All Races'
                            ? hero.race
                            : selectedRace)
                )
                .filter((hero) =>
                    hero.gods.includes(
                        selectedGod === 'All Gods' ? hero.gods[0] : selectedGod // TODO: this is a crude way to do it; however, I could not think of a way to return all in an includes
                    )
                )
                .filter((hero) =>
                    hero.classes.includes(
                        selectedClass === 'All Classes'
                            ? hero.classes[0]
                            : selectedClass // TODO: this is a crude way to do it; however, I could not think of a way to return all in an includes
                    )
                )
                .filter(
                    (hero) =>
                        hero.name ===
                        (selectedHero === 'All Heroes'
                            ? hero.name
                            : selectedHero)
                )
                .map((hero) => (
                    <Grid key={hero.id} xs={12} sm={4} md={3} lg={2}>
                        <HeroCard hero={hero} />
                    </Grid>
                ))}
        </Grid>
    );
}

export default Heroes;
