import { useParams } from 'react-router-dom';
import { Skeleton, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { sanitize } from 'dompurify';
import { useGetPageByNameQuery } from './pagesApi';

function Page() {
    const { pageName } = useParams() as { pageName: string };
    const { data: page, isLoading } = useGetPageByNameQuery(pageName);
    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }

    if (!page) {
        return <Box sx={{ flexGrow: 1 }}>Page not Found</Box>;
    }

    return (
        <Box sx={{ flexGrow: 1, alignContent: 'center' }}>
            <Typography variant="h1">{page.title}</Typography>
            <div dangerouslySetInnerHTML={{ __html: sanitize(page.body) }} />
        </Box>
    );
}

export default Page;
