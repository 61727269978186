import judgementApi from '../../common/judgementApi';
import Hero from './interfaces/Hero';
import HeroClass from './interfaces/HeroClass';

const heroesApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getHeroes: build.query<Hero[], void>({
            query: () => 'heroes',
        }),

        getHeroByName: build.query<Hero, string>({
            query: (heroUrlName) => `heroes/${heroUrlName}`,
        }),

        getHeroByNameWithFaqs: build.query<Hero, string>({
            query: (heroUrlName) => `heroes/${heroUrlName}?faqs=true`,
        }),

        getHeroesByRace: build.query<Hero, string>({
            query: (heroRace) => `heroes/race/${heroRace}`,
        }),

        getHeroesByClass: build.query<Hero, string>({
            query: (heroClass) => `heroes/class/${heroClass}`,
        }),

        getHeroesByGod: build.query<Hero, string>({
            query: (heroGod) => `heroes/god/${heroGod}`,
        }),

        getHeroClasses: build.query<HeroClass[], void>({
            query: () => 'heroclasses',
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
    useGetHeroesQuery,
    useGetHeroByNameQuery,
    useGetHeroByNameWithFaqsQuery,
    useGetHeroesByRaceQuery,
    useGetHeroesByClassQuery,
    useGetHeroesByGodQuery,
    useGetHeroClassesQuery,
} = heroesApi;
