import judgementApi from '../../common/judgementApi';
import TournamentResult from './interfaces/TournamentResult';

const tournamentResultsApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getTournamentResultByTournament: build.query<
            TournamentResult[],
            string
        >({
            query: (tournament) => `tournamentResults/tournament/${tournament}`,
        }),

        // create endpoint for getTournamentResultByPlayer
        getTournamentResultByPlayer: build.query<TournamentResult[], string>({
            query: (player) => `tournamentResults/player/${player}`,
        }),

        // get distinct tournaments
        getTournaments: build.query<string[], void>({
            query: () => 'tournamentResults/tournaments',
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
    useGetTournamentResultByTournamentQuery,
    useGetTournamentResultByPlayerQuery,
    useGetTournamentsQuery,
} = tournamentResultsApi;
