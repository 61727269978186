import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Summon from './interfaces/Summon';

interface SummonCardProps {
    summon: Summon;
}

function SummonCard({ summon }: SummonCardProps) {
    return (
        <Card sx={{ maxWidth: 230 }}>
            <CardMedia
                component="img"
                image={`/images/${summon.avatarUrl}`}
                alt={summon.name}
                sx={{
                    width: 220,
                    height: 220,
                    padding: 2,
                }}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {summon.name}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {`${summon.race}`}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    component={RouterLink}
                    to={`/summons/${summon.urlName}`}
                >
                    View Rules
                </Button>
            </CardActions>
        </Card>
    );
}

export default SummonCard;
