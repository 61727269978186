import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MonsterCard from './MonsterCard';
import { useGetMonstersQuery } from './monstersApi';

function Monsters() {
    const { data: monsters, isLoading } = useGetMonstersQuery();

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Grid container spacing={2}>
            {monsters?.map((monster) => (
                <Grid key={monster.id} xs={12} sm={4} md={3} lg={2}>
                    <MonsterCard monster={monster} />
                </Grid>
            ))}
        </Grid>
    );
}

export default Monsters;
