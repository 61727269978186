import judgementApi from '../../common/judgementApi';
import Monster from './interfaces/Monster';

const monstersApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getMonsters: build.query<Monster[], void>({
            query: () => 'monsters',
        }),

        getMonsterByName: build.query<Monster, string>({
            query: (monsterUrlName) => `monsters/${monsterUrlName}`,
        }),

        getMonsterByNameWithFaqs: build.query<Monster, string>({
            query: (monsterUrlName) => `monsters/${monsterUrlName}?faqs=true`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
    useGetMonstersQuery,
    useGetMonsterByNameQuery,
    useGetMonsterByNameWithFaqsQuery,
} = monstersApi;
