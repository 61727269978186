import judgementApi from '../../common/judgementApi';
import Summon from './interfaces/Summon';

const summonsApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getSummons: build.query<Summon[], void>({
            query: () => 'summons',
        }),

        getSummonByName: build.query<Summon, string>({
            query: (summonUrlName) => `summons/${summonUrlName}`,
        }),

        getSummonByNameWithFaqs: build.query<Summon, string>({
            query: (summonUrlName) => `summons/${summonUrlName}?faqs=true`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
    useGetSummonsQuery,
    useGetSummonByNameQuery,
    useGetSummonByNameWithFaqsQuery,
} = summonsApi;
