import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useGetGameDefinitionsQuery } from './gameDefinitionsApi';
import gameDefinitionItem from '../../common/components/GameDefinitionItem';

function GameDefinitions() {
    const { data: gameDefinitions, isLoading: gameDefinitionsIsLoading } =
        useGetGameDefinitionsQuery();

    if (gameDefinitionsIsLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Grid container spacing={2}>
            {gameDefinitions?.map((gameDefinition) => {
                return gameDefinitionItem(gameDefinition);
            })}
        </Grid>
    );
}

export default GameDefinitions;
