import { Skeleton, Box, Stack, Divider, Paper, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useParams } from 'react-router-dom';
import { useGetSummonByNameWithFaqsQuery } from './summonsApi';
import faqItem from '../../common/components/FaqItem';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

function Summon() {
    const { summonUrlName } = useParams() as { summonUrlName: string };
    const { data: summon, isLoading } =
        useGetSummonByNameWithFaqsQuery(summonUrlName);

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!summon) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>Summon not Found</Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <img
                src={`/images/${summon.avatarUrl}`}
                alt={summon.name}
                width="220"
                height="220"
            />
            <Typography variant="h1">{summon.name}</Typography>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 0, sm: 2 }}
            >
                <Item elevation={0}>MOV: {summon.mov}</Item>
                <Item elevation={0}>MEL: {summon.mel}</Item>
                <Item elevation={0}>RNG: {summon.rng}</Item>
                <Item elevation={0}>MAG: {summon.mag}</Item>
                <Item elevation={0}>AGI: {summon.agi}</Item>
                <Item elevation={0}>RES: {summon.res}</Item>
            </Stack>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >
                <Item elevation={0}>Health: {summon.health}</Item>
                <Item elevation={0}>Summon Cost: {summon.summonCost}</Item>
            </Stack>
            <h3>Weapons</h3>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 0, sm: 2 }}
            >
                <Item elevation={0} sx={{ width: 150 }}>
                    &nbsp;
                </Item>
                <Item elevation={0} sx={{ width: 60 }}>
                    <b>Type</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Cost</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Reach</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Glance</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Solid</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Crit</b>
                </Item>
            </Stack>
            {summon.weapons.map((weapon) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={{ xs: 0, sm: 2 }}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {weapon.name}
                        </Item>
                        <Item elevation={0} sx={{ width: 60 }}>
                            {weapon.type}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.cost}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.reach}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.glance}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.solid}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.crit}
                        </Item>
                    </Stack>
                );
            })}
            {summon.commonInnateAbilities.length === 0 ? (
                ''
            ) : (
                <h3>Common Innate Abilities</h3>
            )}
            <Stack direction="row" spacing={2}>
                {summon.commonInnateAbilities.map((ability) => {
                    return <Item elevation={0}>{ability}</Item>;
                })}
            </Stack>
            {summon.uniqueInnateAbilities.length === 0 ? (
                ''
            ) : (
                <h3>Unique Innate Abilities</h3>
            )}
            {summon.uniqueInnateAbilities.map((ability) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {ability.name}
                        </Item>
                        <Item elevation={0}>{ability.description}</Item>
                    </Stack>
                );
            })}
            {summon.combatManoeuvres.length === 0 ? (
                ''
            ) : (
                <h3>Combat Manoeuvres</h3>
            )}
            {summon.combatManoeuvres.map((combatManoeuvre) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {combatManoeuvre.name} ({combatManoeuvre.cost})
                        </Item>
                        <Item elevation={0}>{combatManoeuvre.description}</Item>
                    </Stack>
                );
            })}
            {summon.activeAbilities.length === 0 ? (
                ''
            ) : (
                <h3>Active Abilities</h3>
            )}
            {summon.activeAbilities.map((activeAbility) => {
                return (
                    <Stack>
                        <Stack
                            direction="row"
                            divider={
                                <Divider orientation="vertical" flexItem />
                            }
                            spacing={2}
                        >
                            <Item elevation={0} sx={{ width: 150 }}>
                                {activeAbility.name} ({activeAbility.cost})
                            </Item>
                            <Item elevation={0}>
                                {activeAbility.description}
                            </Item>
                        </Stack>
                    </Stack>
                );
            })}
            <br />
            {summon.faqs.length === 0 ? (
                ''
            ) : (
                <Grid container spacing={2}>
                    <Typography variant="h1">{summon.name} FAQs</Typography>
                    {summon.faqs.map((faq) => {
                        return faqItem(faq);
                    })}
                </Grid>
            )}
        </Box>
    );
}

export default Summon;
