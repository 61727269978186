import { Skeleton, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Link from '@mui/material/Link';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useGetGameByIdQuery } from './gamesApi';

function Game() {
    const { gameId } = useParams() as { gameId: string };
    const { data: game, isLoading } = useGetGameByIdQuery(gameId);

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!game) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>Game not Found</Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <div>Date:&nbsp;{game.gameDate}</div>
                </Grid>
                <Grid xs={12}>
                    <div>Size:&nbsp;{game.size}</div>
                </Grid>
                <Grid xs={12}>
                    <div>Format:&nbsp;{game.format}</div>
                </Grid>
                <Grid xs={12}>
                    <div>Souls:&nbsp;{game.souls}</div>
                </Grid>
                {game.heroes.map((hero) => {
                    return (
                        <div key={hero.urlName}>
                            <Link
                                component={RouterLink}
                                to={`/heroes/${hero.urlName}`}
                                underline="hover"
                                color="secondary"
                            >
                                {hero.name}
                            </Link>
                            <img
                                src={`/images/${hero.avatarUrl}`}
                                alt={hero.name}
                                width="100"
                                height="100"
                            />
                        </div>
                    );
                })}
            </Grid>
        </Box>
    );
}

export default Game;
