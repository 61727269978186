import judgementApi from '../../common/judgementApi';
import PlayerRanking from './interfaces/PlayerRanking';

const playerRankingsApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getPlayerRankings: build.query<PlayerRanking[], void>({
            query: () => 'playerrankings/5v5',
        }),
        getPlayerRankings3v3: build.query<PlayerRanking[], void>({
            query: () => 'playerrankings/3v3',
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetPlayerRankingsQuery, useGetPlayerRankings3v3Query } =
    playerRankingsApi;
