import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    CardActions,
    Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Artefact from './interfaces/Artefact';

interface ArtefactCardProps {
    artefact: Artefact;
}

function ArtefactCard({ artefact }: ArtefactCardProps) {
    return (
        <Card sx={{ maxWidth: 400 }}>
            <CardMedia
                component="img"
                image={`/images/${artefact.avatarUrl}`}
                alt={artefact.avatarUrl}
                sx={{
                    width: 220,
                    height: 220,
                }}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {artefact.name}
                </Typography>
                <Typography paragraph>{artefact.type}</Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    component={RouterLink}
                    to={`/artefacts/${artefact.urlName}`}
                >
                    View Rules
                </Button>
            </CardActions>
        </Card>
    );
}

export default ArtefactCard;
