import judgementApi from '../../common/judgementApi';
import God from './interfaces/God';

const godsApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getGods: build.query<God[], void>({
            query: () => 'gods',
        }),

        getGodByName: build.query<God, string>({
            query: (godUrlName) => `gods/${godUrlName}`,
        }),

        getGodByNameWithFaqs: build.query<God, string>({
            query: (godUrlName) => `gods/${godUrlName}?faqs=true`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
    useGetGodsQuery,
    useGetGodByNameQuery,
    useGetGodByNameWithFaqsQuery,
} = godsApi;
