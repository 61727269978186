import judgementApi from '../../common/judgementApi';
import Page from './interfaces/Page';

const pagesApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getPages: build.query<Page[], void>({
            query: () => 'pages',
        }),

        getPageByName: build.query<Page, string>({
            query: (pageName) => `pages/${pageName}`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetPagesQuery, useGetPageByNameQuery } = pagesApi;
