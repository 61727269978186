import { Skeleton, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { sanitize } from 'dompurify';
import { useGetPageByNameQuery } from './pagesApi';

function CurrentMapPool() {
    const { data: page, isLoading } = useGetPageByNameQuery('starterboxmaps');
    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }

    if (!page) {
        return <Box sx={{ flexGrow: 1 }}>Page not Found</Box>;
    }

    return (
        <Box sx={{ flexGrow: 1, alignContent: 'center' }}>
            <Typography variant="h1">{page.title}</Typography>
            <div dangerouslySetInnerHTML={{ __html: sanitize(page.body) }} />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="3v3 map 2"
                src="/images/3v3-starter-map1.jpg"
            />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="3v3 map 2"
                src="/images/3v3-starter-map2.jpg"
            />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="3v3 map 3"
                src="/images/3v3-starter-map3.jpg"
            />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="3v3 map 4"
                src="/images/3v3-starter-map4.jpg"
            />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="5v5 map 2"
                src="/images/5v5-starter-map1.jpg"
            />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="5v5 map 2"
                src="/images/5v5-starter-map2.jpg"
            />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="5v5 map 3"
                src="/images/5v5-starter-map3.jpg"
            />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="5v5 map 4"
                src="/images/5v5-starter-map4.jpg"
            />
            <Box
                component="img"
                sx={{
                    height: 700,
                    width: 700,
                    maxHeight: { xs: 300, sm: 450, md: 600, lg: 700 },
                    maxWidth: { xs: 300, sm: 450, md: 600, lg: 700 },
                }}
                alt="5v5 map 5"
                src="/images/5v5-starter-map5.jpg"
            />
        </Box>
    );
}

export default CurrentMapPool;
