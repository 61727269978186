import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SummonCard from './SummonCard';
import { useGetSummonsQuery } from './summonsApi';

function Summons() {
    const { data: summons, isLoading } = useGetSummonsQuery();

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Grid container spacing={2}>
            {summons?.map((summon) => (
                <Grid key={summon.id} xs={12} sm={6} md={4} lg={3}>
                    <SummonCard summon={summon} />
                </Grid>
            ))}
        </Grid>
    );
}

export default Summons;
