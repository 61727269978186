import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Hero from './interfaces/Hero';

interface HeroCardProps {
    hero: Hero;
}

function HeroCard({ hero }: HeroCardProps) {
    return (
        <Card
            sx={{
                maxWidth: 230,
                margin: '0 auto',
                padding: '0.1em',
            }}
        >
            <CardMedia
                component="img"
                image={`/images/${hero.avatarUrl}`}
                alt={hero.name}
                sx={{
                    width: '100%',
                    height: 'auto',
                    padding: 2,
                }}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {hero.name}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {`${hero.race} ${hero.title}`}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    component={RouterLink}
                    to={`/heroes/${hero.urlName}`}
                >
                    View Rules
                </Button>
            </CardActions>
        </Card>
    );
}

export default HeroCard;
