import judgementApi from '../../common/judgementApi';
import Faq from './interfaces/Faq';

const faqsApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getFaqs: build.query<Faq[], void>({
            query: () => 'faqs',
        }),

        getFaqTags: build.query<string[], void>({
            query: () => 'faqs/faqtags',
        }),

        getFaqByTag: build.query<Faq, string>({
            query: (faqTag) => `faqs/${faqTag}`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetFaqsQuery, useGetFaqTagsQuery, useGetFaqByTagQuery } =
    faqsApi;
