import { Skeleton, FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { useState, useEffect } from 'react';
import ArtefactCard from './ArtefactCard';
import { useGetArtefactsQuery } from './artefactsApi';

function Artefacts() {
    useEffect(() => {
        document.title = 'Artefacts - Judgement: Eternal Champions';
    }, []);
    const { data: artefacts, isLoading } = useGetArtefactsQuery();
    const [selectedCategory, setSelectedCategory] = useState('General');
    const [selectedType, setSelectedType] = useState('All Types');

    const handleCategoryChange = (event: SelectChangeEvent) => {
        setSelectedCategory(event.target.value as string);
    };
    const handleTypeChange = (event: SelectChangeEvent) => {
        setSelectedType(event.target.value as string);
    };

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Grid container spacing={2}>
            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                <InputLabel id="artefactLabel">Category</InputLabel>
                <Select
                    labelId="selectCategorylabel"
                    id="selectCategory"
                    value={selectedCategory}
                    label="Artefact Category"
                    onChange={handleCategoryChange}
                >
                    <MenuItem value="General">General</MenuItem>
                    <MenuItem value="Monster">Monster</MenuItem>
                    <MenuItem value="Sacred">Sacred</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                <InputLabel id="artefactLabel">Type</InputLabel>
                <Select
                    labelId="selectTypelabel"
                    id="selectType"
                    value={selectedType}
                    label="Artefact Type"
                    onChange={handleTypeChange}
                >
                    <MenuItem value="All Types">All Types</MenuItem>
                    <MenuItem value="Offensive">Offensive</MenuItem>
                    <MenuItem value="Defensive">Defensive</MenuItem>
                </Select>
            </FormControl>
            {artefacts
                ?.filter(
                    (artefact) =>
                        artefact.category ===
                        (selectedCategory === 'All Categories'
                            ? artefact.category
                            : selectedCategory)
                )
                .filter(
                    (artefact) =>
                        artefact.type ===
                        (selectedType === 'All Types'
                            ? artefact.type
                            : selectedType)
                )
                .map((artefact) => (
                    <Grid key={artefact.id} xs={12} sm={4} md={3} lg={2}>
                        <ArtefactCard artefact={artefact} />
                    </Grid>
                ))}
        </Grid>
    );
}

export default Artefacts;
