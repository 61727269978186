import { Skeleton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetGamesQuery } from './gamesApi';

function Games() {
    const { data: games, isLoading } = useGetGamesQuery();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'size', headerName: 'Size', width: 150, editable: true },
        { field: 'format', headerName: 'Format', width: 150, editable: true },
        { field: 'gameDate', headerName: 'Date', width: 150, editable: true },
    ];

    // declare empty string array
    const noGames: string[] = [];

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return <DataGrid columns={columns} rows={games ?? noGames} />;
}

export default Games;
