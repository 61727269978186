import { Skeleton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useGetPlayerRankingsQuery } from './playerRankingsApi';

function PlayerRankings() {
    const { data: playerRankings, isLoading } = useGetPlayerRankingsQuery();

    // declare empty string array
    // const noTournamentResult: string[] = [];
    const data = playerRankings ?? [];

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <div>
            <h2>Player Rankings 5s</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Pos</TableCell>
                            <TableCell align="left">Player</TableCell>
                            <TableCell align="center">Points</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.player}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.position}
                                </TableCell>
                                <TableCell align="left">
                                    <Link
                                        component={RouterLink}
                                        to={`/tournamentresults/player/${row.player}`}
                                        underline="hover"
                                        color="secondary"
                                    >
                                        {row.player}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    {row.points}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default PlayerRankings;
