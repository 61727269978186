import { Skeleton, FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useGetFaqsQuery, useGetFaqTagsQuery } from './faqsApi';
import faqItem from '../../common/components/FaqItem';

function Faqs() {
    const { data: faqs, isLoading: faqsIsLoading } = useGetFaqsQuery();
    const { data: faqTags, isLoading: faqTagsIsLoading } = useGetFaqTagsQuery();
    const [faqTag, setFaqTag] = useState('general');
    const handleTagChange = (event: SelectChangeEvent) => {
        setFaqTag(event.target.value as string);
    };

    if (faqsIsLoading || faqTagsIsLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Grid container spacing={2}>
            <FormControl sx={{ paddingBottom: 1 }} fullWidth>
                <InputLabel id="tagLabel">Tag Filter</InputLabel>
                <Select
                    labelId="selectTaglabel"
                    id="selectTag"
                    value={faqTag}
                    label="Race"
                    onChange={handleTagChange}
                >
                    <MenuItem value="general">general</MenuItem>
                    {faqTags?.map((tag) => (
                        <MenuItem value={tag}>{tag}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {faqs
                ?.filter((faq) => faq.tags.includes(faqTag))
                .map((faq) => {
                    return faqItem(faq);
                })}
        </Grid>
    );
}

export default Faqs;
