import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import GodCard from './GodCard';
import { useGetGodsQuery } from './godsApi';

function Gods() {
    const { data: gods, isLoading } = useGetGodsQuery();

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Grid container spacing={2}>
            {gods?.map((god) => (
                <Grid key={god.id} xs={12} sm={6} lg={4}>
                    <GodCard god={god} />
                </Grid>
            ))}
        </Grid>
    );
}

export default Gods;
