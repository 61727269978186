import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { sanitize } from 'dompurify';
import CommonInnateAbility from '../../features/commonInnateAbilities/interfaces/CommonInnateAbility';

function CommonInnateAbilityItem({
    id,
    name,
    description,
}: CommonInnateAbility) {
    return (
        <Grid key={id} xs={12}>
            <Box sx={{ border: 1, p: 2, borderColor: 'grey.300' }}>
                <Typography variant="h1">{name}</Typography>
                <div
                    dangerouslySetInnerHTML={{ __html: sanitize(description) }}
                />
            </Box>
        </Grid>
    );
}

export default CommonInnateAbilityItem;
