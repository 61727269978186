import { useEffect } from 'react';
import { Skeleton, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useParams } from 'react-router-dom';
import { useGetArtefactByNameQuery } from './artefactsApi';
import artefactItem from '../../common/components/ArtefactItem';

function Artefact() {
    useEffect(() => {
        document.title = 'Artefacts - Judgement: Eternal Champions';
    }, []);
    const { artefactUrlName } = useParams() as { artefactUrlName: string };
    const { data: artefact, isLoading } =
        useGetArtefactByNameQuery(artefactUrlName);

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }

    if (!artefact) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>Artefact not Found</Grid>
                </Grid>
            </Box>
        );
    }

    return artefactItem(artefact);
}

export default Artefact;
