import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { sanitize } from 'dompurify';
import GameDefinition from '../../features/gameDefinitions/interfaces/GameDefinition';

function GameDefinitionItem({ id, name, description }: GameDefinition) {
    return (
        <Grid key={id} xs={12}>
            <Box sx={{ border: 1, p: 2, borderColor: 'grey.300' }}>
                <Typography variant="h1">{name}</Typography>
                <div
                    dangerouslySetInnerHTML={{ __html: sanitize(description) }}
                />
            </Box>
        </Grid>
    );
}

export default GameDefinitionItem;
