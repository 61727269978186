import judgementApi from '../../common/judgementApi';
import GameDefinition from './interfaces/GameDefinition';

const gameDefinitionsApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getGameDefinitions: build.query<GameDefinition[], void>({
            query: () => 'gameDefinitions',
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetGameDefinitionsQuery } = gameDefinitionsApi;
