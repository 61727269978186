import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Box from '@mui/material/Box';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ClerkProvider } from '@clerk/clerk-react';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root')!;
const root = createRoot(container);
// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
    throw new Error('Missing Publishable Key');
}

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Box>
                <BrowserRouter>
                    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
                        <App />
                    </ClerkProvider>
                </BrowserRouter>
            </Box>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
