import { Skeleton, Box, Stack, Divider, Paper, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useGetHeroByNameWithFaqsQuery } from './heroesApi';
import faqItem from '../../common/components/FaqItem';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontFamily: 'roboto normal',
}));

function Hero() {
    const { heroUrlName } = useParams() as { heroUrlName: string };
    const { data: hero, isLoading } =
        useGetHeroByNameWithFaqsQuery(heroUrlName);

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!hero) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <h3>Hero not Found</h3>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <img
                src={`/images/${hero.avatarUrl}`}
                alt={hero.name}
                width="220"
                height="220"
            />
            <Typography variant="h1">{hero.name}</Typography>
            <div>
                <i>{`${hero.race} ${hero.title}`}</i>
            </div>
            <Stack direction="row" spacing={2}>
                <Item elevation={0}>Classes: </Item>
                {hero.classes.map((heroClass) => {
                    return <Item elevation={0}>{heroClass}</Item>;
                })}
            </Stack>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 0, sm: 2 }}
            >
                <Item elevation={0}>MOV: {hero.mov}</Item>
                <Item elevation={0}>MEL: {hero.mel}</Item>
                <Item elevation={0}>RNG: {hero.rng}</Item>
                <Item elevation={0}>MAG: {hero.mag}</Item>
                <Item elevation={0}>AGI: {hero.agi}</Item>
                <Item elevation={0}>RES: {hero.res}</Item>
                <Item elevation={0}>SH: {hero.soulHarvest}</Item>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Item elevation={0}>Health: </Item>
                {hero.health.map((heroHealth) => {
                    return <Item elevation={0}>{heroHealth}</Item>;
                })}
            </Stack>
            <Stack direction="row" spacing={2}>
                <Item elevation={0}>Gods: </Item>
                {hero.gods.map((god) => {
                    return (
                        <Item elevation={0}>
                            <Link
                                component={RouterLink}
                                to={`/gods/${god.toLowerCase()}`}
                                underline="hover"
                                color="secondary"
                            >
                                {god}
                            </Link>
                        </Item>
                    );
                })}
            </Stack>
            <h3>Weapons</h3>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 0, sm: 2 }}
            >
                <Item elevation={0} sx={{ width: 150 }}>
                    &nbsp;
                </Item>
                <Item elevation={0} sx={{ width: 60 }}>
                    <b>Type</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Cost</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Reach</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Glance</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Solid</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Crit</b>
                </Item>
            </Stack>
            {hero.weapons.map((weapon) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={{ xs: 0, sm: 2 }}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {weapon.name}
                        </Item>
                        <Item elevation={0} sx={{ width: 60 }}>
                            {weapon.type}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.cost}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.reach}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.glance}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.solid}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.crit}
                        </Item>
                    </Stack>
                );
            })}
            {hero.commonInnateAbilities.length === 0 ? (
                ''
            ) : (
                <h3>Common Innate Abilities</h3>
            )}
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 0, sm: 2 }}
                justifyContent="flex-start"
            >
                {hero.commonInnateAbilities.map((ability) => {
                    return <Item elevation={0}>{ability}</Item>;
                })}
            </Stack>
            {hero.uniqueInnateAbilities.length === 0 ? (
                ''
            ) : (
                <h3>Unique Innate Abilities</h3>
            )}
            {hero.uniqueInnateAbilities.map((ability) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {ability.name}
                        </Item>
                        <Item elevation={0}>{ability.description}</Item>
                    </Stack>
                );
            })}
            {hero.combatManoeuvres.length === 0 ? (
                ''
            ) : (
                <h3>Combat Manoeuvres</h3>
            )}
            {hero.combatManoeuvres.map((combatManoeuvre) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {combatManoeuvre.name} ({combatManoeuvre.cost})
                        </Item>
                        <Item elevation={0}>{combatManoeuvre.description}</Item>
                    </Stack>
                );
            })}
            {hero.activeAbilities.length === 0 ? '' : <h3>Active Abilities</h3>}
            {hero.activeAbilities.map((activeAbility) => {
                return (
                    <Stack>
                        <Stack
                            direction="row"
                            divider={
                                <Divider orientation="vertical" flexItem />
                            }
                            spacing={2}
                        >
                            <Item elevation={0} sx={{ width: 150 }}>
                                {activeAbility.name} ({activeAbility.cost})
                            </Item>
                            <Item elevation={0}>
                                {activeAbility.description}
                            </Item>
                        </Stack>
                    </Stack>
                );
            })}
            <h2>Level 2</h2>
            {hero.level2.active_abilities.length === 0 ? (
                ''
            ) : (
                <h3>Active Abilities</h3>
            )}
            {hero.level2.active_abilities.map((activeAbility) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {activeAbility.name}({activeAbility.cost})
                        </Item>
                        <Item elevation={0}>{activeAbility.description}</Item>
                    </Stack>
                );
            })}
            {hero.level2.unique_innate_abilities.length === 0 ? (
                ''
            ) : (
                <h3>Unique Innate Abilities</h3>
            )}
            {hero.level2.unique_innate_abilities.map((uniqueInnateAbility) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {uniqueInnateAbility.name}
                        </Item>
                        <Item elevation={0}>
                            {uniqueInnateAbility.description}
                        </Item>
                    </Stack>
                );
            })}
            {hero.level2.combat_manoeuvres.length === 0 ? (
                ''
            ) : (
                <h3>Combat Manouevres</h3>
            )}
            {hero.level2.combat_manoeuvres.map((combatManoeuvre) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {combatManoeuvre.name}({combatManoeuvre.cost})
                        </Item>
                        <Item elevation={0}>{combatManoeuvre.description}</Item>
                    </Stack>
                );
            })}
            <h2>Level 3</h2>
            {hero.level3.active_abilities.length === 0 ? (
                ''
            ) : (
                <h3>Active Abilities</h3>
            )}
            {hero.level3.active_abilities.map((activeAbility) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {activeAbility.name}({activeAbility.cost})
                        </Item>
                        <Item elevation={0}>{activeAbility.description}</Item>
                    </Stack>
                );
            })}
            {hero.level3.unique_innate_abilities.length === 0 ? (
                ''
            ) : (
                <h3>Unique Innate Abilities</h3>
            )}
            {hero.level3.unique_innate_abilities.map((uniqueInnateAbility) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {uniqueInnateAbility.name}
                        </Item>
                        <Item elevation={0}>
                            {uniqueInnateAbility.description}
                        </Item>
                    </Stack>
                );
            })}
            {hero.level3.combat_manoeuvres.length === 0 ? (
                ''
            ) : (
                <h3>Combat Manouevres</h3>
            )}
            {hero.level3.combat_manoeuvres.map((combatManoeuvre) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {combatManoeuvre.name}({combatManoeuvre.cost})
                        </Item>
                        <Item elevation={0}>{combatManoeuvre.description}</Item>
                    </Stack>
                );
            })}
            <br />
            {hero.faqs.length === 0 ? (
                ''
            ) : (
                <Grid container spacing={2}>
                    <Typography variant="h1">{hero.name} FAQs</Typography>
                    {hero.faqs.map((faq) => {
                        return faqItem(faq);
                    })}
                </Grid>
            )}
        </Box>
    );
}

export default Hero;
