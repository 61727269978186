import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Faq from '../../features/faqs/interfaces/Faq';

function FaqItem({ id, question, answer }: Faq) {
    return (
        <Grid key={id} xs={12}>
            <Box sx={{ border: 1, p: 2, borderColor: 'grey.300' }}>
                <p>
                    <b>Q:&nbsp;</b>
                    {question}
                </p>
                <p>
                    <b>A:&nbsp;</b>
                    {answer}
                </p>
            </Box>
        </Grid>
    );
}

export default FaqItem;
