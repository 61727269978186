import { Skeleton, Box, Stack, Divider, Paper, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useParams } from 'react-router-dom';
import { useGetMonsterByNameWithFaqsQuery } from './monstersApi';
import faqItem from '../../common/components/FaqItem';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

function Monster() {
    const { monsterUrlName } = useParams() as { monsterUrlName: string };
    const { data: monster, isLoading } =
        useGetMonsterByNameWithFaqsQuery(monsterUrlName);

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    if (!monster) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>Monster not Found</Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <img
                src={`/images/${monster.avatarUrl}`}
                alt={monster.name}
                width="220"
                height="220"
            />
            <Typography variant="h1">{monster.name}</Typography>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 0, sm: 2 }}
            >
                <Item elevation={0}>MOV: {monster.mov}</Item>
                <Item elevation={0}>MEL: {monster.mel}</Item>
                <Item elevation={0}>RNG: {monster.rng}</Item>
                <Item elevation={0}>MAG: {monster.mag}</Item>
                <Item elevation={0}>AGI: {monster.agi}</Item>
                <Item elevation={0}>RES: {monster.res}</Item>
            </Stack>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >
                <Item elevation={0}>Health: {monster.health}</Item>
                <Item elevation={0}>Tier: {monster.tier}</Item>
                <Item elevation={0}>Bounty: {monster.bounty}</Item>
            </Stack>
            <h3>Weapons</h3>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 0, sm: 2 }}
            >
                <Item elevation={0} sx={{ width: 150 }}>
                    &nbsp;
                </Item>
                <Item elevation={0} sx={{ width: 60 }}>
                    <b>Type</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Cost</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Reach</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Glance</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Solid</b>
                </Item>
                <Item elevation={0} sx={{ width: 50 }}>
                    <b>Crit</b>
                </Item>
            </Stack>
            {monster.weapons.map((weapon) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={{ xs: 0, sm: 2 }}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {weapon.name}
                        </Item>
                        <Item elevation={0} sx={{ width: 60 }}>
                            {weapon.type}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.cost}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.reach}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.glance}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.solid}
                        </Item>
                        <Item elevation={0} sx={{ width: 50 }}>
                            {weapon.crit}
                        </Item>
                    </Stack>
                );
            })}
            {monster.commonInnateAbilities.length === 0 ? (
                ''
            ) : (
                <h3>Common Innate Abilities</h3>
            )}
            <Stack direction="row" spacing={2}>
                {monster.commonInnateAbilities.map((ability) => {
                    return <Item elevation={0}>{ability}</Item>;
                })}
            </Stack>
            {monster.uniqueInnateAbilities.length === 0 ? (
                ''
            ) : (
                <h3>Unique Innate Abilities</h3>
            )}
            {monster.uniqueInnateAbilities.map((ability) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {ability.name}
                        </Item>
                        <Item elevation={0}>{ability.description}</Item>
                    </Stack>
                );
            })}
            {monster.combatManoeuvres.length === 0 ? (
                ''
            ) : (
                <h3>Combat Manoeuvres</h3>
            )}
            {monster.combatManoeuvres.map((combatManoeuvre) => {
                return (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Item elevation={0} sx={{ width: 150 }}>
                            {combatManoeuvre.name} ({combatManoeuvre.cost})
                        </Item>
                        <Item elevation={0}>{combatManoeuvre.description}</Item>
                    </Stack>
                );
            })}
            <br />
            {monster.faqs.length === 0 ? (
                ''
            ) : (
                <Grid container spacing={2}>
                    <Typography variant="h1">{monster.name} FAQs</Typography>
                    {monster.faqs.map((faq) => {
                        return faqItem(faq);
                    })}
                </Grid>
            )}
        </Box>
    );
}

export default Monster;
