import { Skeleton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useGetTournamentsQuery } from './tournamentResultApi';

function Tournaments() {
    const { data: tournaments, isLoading } = useGetTournamentsQuery();

    // declare empty string array
    // const noTournaments: string[] = [];

    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <div>
            The following tournaments are included in the rankings for the
            current season. Click on the tournament name to see the results.
            <ul>
                {tournaments?.map((tournament) => (
                    <li>
                        <Link
                            component={RouterLink}
                            to={`/tournamentresults/tournament/${tournament}`}
                            underline="hover"
                            color="secondary"
                        >
                            {tournament}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Tournaments;
