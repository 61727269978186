import judgementApi from '../../common/judgementApi';
import Game from './interfaces/Game';

const gamesApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getGames: build.query<Game[], void>({
            query: () => 'games',
        }),

        getGameById: build.query<Game, string>({
            query: (id) => `games/${id}`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetGamesQuery, useGetGameByIdQuery } = gamesApi;
