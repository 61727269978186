import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useGetCommonInnateAbilitiesQuery } from './commonInnateAbilitiesApi';
import commonInnateAbilityItem from '../../common/components/CommonInnateAbilityItem';

function CommonInnateAbilities() {
    const {
        data: commonInnateAbilities,
        isLoading: commonInnateAbilitiesIsLoading,
    } = useGetCommonInnateAbilitiesQuery();

    if (commonInnateAbilitiesIsLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }
    return (
        <Grid container spacing={2}>
            {commonInnateAbilities?.map((commonInnateAbility) => {
                return commonInnateAbilityItem(commonInnateAbility);
            })}
        </Grid>
    );
}

export default CommonInnateAbilities;
