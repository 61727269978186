import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import judgementApi from '../common/judgementApi';

export const store = configureStore({
    reducer: {
        [judgementApi.reducerPath]: judgementApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(judgementApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
