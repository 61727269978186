import { Skeleton, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { sanitize } from 'dompurify';
import { useGetPageByNameQuery } from '../pages/pagesApi';

function Home() {
    const { data: page, isLoading } = useGetPageByNameQuery('homepage');
    if (isLoading) {
        return <Skeleton variant="rectangular" height={60} />;
    }

    if (!page) {
        return <Box sx={{ flexGrow: 1 }}>Page not Found</Box>;
    }

    return (
        <Box sx={{ flexGrow: 1, alignContent: 'center' }}>
            <Typography variant="h1">{page.title}</Typography>
            <Box
                component="img"
                sx={{
                    height: 600,
                    width: 600,
                    maxHeight: { xs: 300, sm: 450, md: 600 },
                    maxWidth: { xs: 300, sm: 450, md: 600 },
                }}
                alt="Judgement: Eternal Champions"
                src="/images/box-artwork.jpg"
            />
            <div dangerouslySetInnerHTML={{ __html: sanitize(page.body) }} />
        </Box>
    );
}

export default Home;
