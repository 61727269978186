import judgementApi from '../../common/judgementApi';
import CommonInnateAbility from './interfaces/CommonInnateAbility';

const commonInnateAbilitiesApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getCommonInnateAbilities: build.query<CommonInnateAbility[], void>({
            query: () => 'commonInnateAbilities',
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetCommonInnateAbilitiesQuery } = commonInnateAbilitiesApi;
