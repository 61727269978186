import judgementApi from '../../common/judgementApi';
import Artefact from './interfaces/Artefact';

const artefactsApi = judgementApi.injectEndpoints({
    endpoints: (build) => ({
        getArtefacts: build.query<Artefact[], void>({
            query: () => 'artefacts',
        }),

        getArtefactByName: build.query<Artefact, string>({
            query: (artefactUrlName) => `artefacts/${artefactUrlName}`,
        }),
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetArtefactsQuery, useGetArtefactByNameQuery } = artefactsApi;
